import { FormattedHTMLMessage, FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import React from "react";
import Helmet from "react-helmet";
import Container from "../components/Container";
import {
  circleSmall,
  waveHorizontalLarge,
  waveVerticalSmall
} from "../components/Images";
import Layout from "../components/Layout";
import Row from "../components/Row";
import styled from "../styling/styled";
import { theme } from "../styling/theme";

const Content = styled.div`
  padding-top: 120px;
  padding-bottom: 60px;
  background-size: 160px 17px, 160px 17px, 17px 17px, 17px 47px;
  background-image: url(${waveHorizontalLarge}), url(${waveHorizontalLarge}),
    url(${circleSmall}), url(${waveVerticalSmall});
  background-repeat: no-repeat;
  background-position: left calc(${theme.container.background} - 277px) top 90px,
    right calc(${theme.container.background} - 320px) top 150px,
    left calc(${theme.container.background} - 87px) top 43%,
    left calc(${theme.container.background} - 127px) bottom 18%;

  ${[theme.media.mobileMenuVisible]} {
    padding-top: 75px;
    padding-bottom: 30px;
    min-height: 0;
  }
`;

const StyledRow = styled(Row)`
  align-items: flex-start;
  position: relative;

  ${[theme.media.maxM]} {
    display: block;
  }
`;

const Col6 = styled.div`
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
  h4:has(+ dl) {
    margin-bottom: 0;
  }
  h4 + dl {
    margin-top: 0;
    margin-left: 16px;
  }
  dt h5 {
    margin-bottom: 0;
  }
`;

const Spacer = styled.div`
  height: 30px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-top: 1px solid #ddd;
`;

class JobsPage extends React.Component {
  render() {
    const intl = this.props.intl;
    const numberOfPositions = 3;
    return (
      <>
        <Helmet
          title={
            intl.formatMessage({ id: "pages.jobs.title" }) + " | Eledio.com"
          }
          meta={[
            {
              property: "og:title",
              content: intl.formatMessage({ id: "pages.jobs.title" })
            }
          ]}
        >
          <html lang={intl.locale} />
        </Helmet>
        <Layout>
          <Content>
            <Container>
              <StyledRow spacing={15}>
                <Col6>
                  <h1>
                    <FormattedMessage id="pages.jobs.title" /> ({numberOfPositions})
                  </h1>
                  <ul>
                    {Array.from({ length: numberOfPositions }).map((_, index) => (
                      <li key={index}>
                        <a href={"#" + intl.formatMessage({ id: `pages.jobs.positions.${index}.titleUrl` })}>
                          <FormattedMessage id={`pages.jobs.positions.${index}.title`} />
                        </a>
                      </li>
                    ))}
                  </ul>
                  <Spacer />
                  <>
                  {Array.from({ length: numberOfPositions }).map((_, index) => (
                    <React.Fragment key={index}>
                      <h3 id={intl.formatMessage({ id: `pages.jobs.positions.${index}.titleUrl` })}>
                        <FormattedMessage id={`pages.jobs.positions.${index}.title`} />
                      </h3>
                      <>
                        <FormattedHTMLMessage id={`pages.jobs.positions.${index}.description`} />
                      </>
                      <br />
                      <h4>
                        <FormattedMessage id={`pages.jobs.contactUs.title`} />
                        <br />
                        <small><FormattedHTMLMessage id={`pages.jobs.contactUs.text`} /></small>
                      </h4> 
                      <dl>
                        <dt>
                          <h5><FormattedHTMLMessage id={`pages.jobs.contactUs.name`} /></h5>
                        </dt>
                        <dd>
                          <a href={`mailto:${intl.formatMessage({ id: `pages.jobs.contactUs.email` })}`}>
                            <FormattedHTMLMessage id={`pages.jobs.contactUs.email`} />
                          </a>
                          <br />
                          <a href={`tel:${intl.formatMessage({ id: `pages.jobs.contactUs.phone` })}`}>
                            <FormattedHTMLMessage id={`pages.jobs.contactUs.phone`} />
                          </a>
                        </dd>
                      </dl>
                      <Spacer />
                    </React.Fragment>
                    ))}
                  </>
                </Col6>
              </StyledRow>
            </Container>
          </Content>
        </Layout>
      </>
    );
  }
}

export default injectIntl(JobsPage);
